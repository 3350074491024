import axios from "axios";
// import { toast } from "react-toastify";
let paxios = axios.create();

export const errorCatch = () => {};

paxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      // dispatch(setCurrentUser(null));
      // dispatch(setCustomerAddresses(null));
      // dispatch(setUserOrder(null));
      // dispatch(setTransferMoney(null));
      // localStorage.removeItem("token");
      // window.location.reload();
      // window.location = "/Login";
      return Promise.reject(error);
    } else if (500 === error.response.status) {
      // toast.error("Sorry. something went wrong .A team of highly trained developers has been dispatched to deal with this situation!");
    } else {
      return Promise.reject(error?.response?.data?.errors);
    }
  }
);

export default class Api {
  static async get(route, headers) {
    return await this.execute(route, null, "get", headers);
  }

  static async put(route, params, headers) {
    return await this.execute(route, params, "put", headers);
  }

  static async post(route, params, headers) {
    return await this.execute(route, params, "post", headers);
  }

  static async delete(route, params, headers) {
    return await this.execute(route, params, "delete", headers);
  }

  static async execute(route, params, verb, headersParam) {
    // let request = [
    //   "https://chauffeur-be-staging-j2ezitroia-uc.a.run.app/api/" + route,
    // ];
    let request = [
      "https://chauffeur-be-prod-261156411298.us-central1.run.app/api/" + route,
    ];
    // let request = ["http://native-001-site30.ctempurl.com/api/" + route];
    // let config = {
    //     headers:
    //         headersParam ? {
    //             'Authorization': localStorage.getItem("token"),
    //             ...headersParam,
    //         } : {
    //             'Authorization': localStorage.getItem("token"),
    //             'Content-Type': 'application/json;'
    //         }
    // }
    let config;
    if (localStorage.getItem("token")) {
      config = {
        headers: headersParam
          ? {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Encoding": "gzip, compress",
              ...headersParam,
            }
          : {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Encoding": "gzip, compress",
              "Content-Type": "application/json;",
            },
      };
    }
    if (localStorage.getItem("deviceId")) {
      if (!!!localStorage.getItem("token")) {
        config = {
          headers: headersParam
            ? {
                deviceId: localStorage.getItem("deviceId"),
                ...headersParam,
              }
            : {
                deviceId: localStorage.getItem("deviceId"),
                "Content-Type": "application/json;",
              },
        };
      }
    }
    // console.log(config)
    if (route === "login") {
      config = {
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      };
    }

    if (params) request.push(params);
    let result = await paxios[verb](...request, config);
    return result;
  }
}
