import { ConfigProvider } from "antd";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { useEffect } from "react";
import AOS from "aos";
import Routes from "./Routes";
import Api from "./public/network/ApiConfig";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentLang, setNotificationList } from "./store/reducers/user";
import NavBottom from "./components/NavBottom/NavBottom";
import en from "./public/localization/en";
import ar from "./public/localization/ar";
import { onMessageListener, requestForToken } from "./firebase";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { Helmet } from "react-helmet";
import wtsIcon from "../src/public/images/wts-icon.png";
import { useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import uts from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);
dayjs.extend(uts);
function App() {
  const { currentLang, currentUser } = useSelector(
    (state) => state?.entities?.user
  );
  const params = useParams();
  const location = useLocation();

  const t = currentLang === "ar" ? ar : en;
  const dispatch = useDispatch();
  // const { currentLang } = useSelector((state) => state?.entities?.user);
  ConfigProvider.config({
    theme: {
      primaryColor: "#612166",
    },
  });
  // const chanageLang = ({ key }) => {
  //   dispatch(setCurrentLang(key));
  //   document.getElementsByTagName("html")[0].setAttribute("lang", key);
  // };
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }
  const pathname = location?.pathname;

  const isArabicOrEnglish = pathname.match(/^\/(ar|en)(\/|$)/);

  if (isArabicOrEnglish && location?.pathname !== "/") {
    const isArabic = pathname.match(/^\/ar(\/|$)/);
    if (isArabic) {
      console.log("Pathname includes isArabic");
      if (currentLang === "en") {
        // chanageLang("ar");
        dispatch(setCurrentLang("ar"));
        document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
      }
    } else {
      if (currentLang === "ar") {
        // chanageLang("en");
        dispatch(setCurrentLang("en"));
        document.getElementsByTagName("html")[0].setAttribute("lang", "en");
      }
    }
  } else {
    console.log("Pathname does not include either Arabic or English");
  }

  useEffect(() => {
    AOS.init({
      offset: 120,
    });
    document.getElementsByTagName("html")[0].setAttribute("lang", currentLang);
    if (currentUser) {
      handelAddNotification();
      onMessageListener((payload) => {
        console.log(payload, "get notifay");
        Api.get("Users/GetAllNotification?Page=0").then(
          (res) => {
            console.log(res?.data);
            dispatch(setNotificationList(res?.data?.model));
          },
          (err) => {
            console.log(err);
          }
        );
      });
    }
  }, []);

  const handelAddNotification = async () => {
    const userToken = await requestForToken();
    let body = {
      Token: userToken,
      OS: "WEB",
    };
    Api.post("Users/AddPushToken", body).then(
      (res) => {
        Api.get("Users/GetAllNotification?Page=0").then(
          (res) => {
            dispatch(setNotificationList(res?.data?.model));
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (err) => {
        // message.error(err?.message);
      }
    );
  };

  return (
    <ConfigProvider direction={currentLang === "en" ? "ltr" : "rtl"}>
      <Header t={t} />
      <ScrollToTop />
      <main className="app-content">
        <Helmet>
          {/* <title>{t.ChauffeurTitle}</title> */}
          <title>{t.ChauffeurTitle}</title>
          <meta name="description" content={t.ChauffeurDescription} />
          {/* <meta name="description" content={t.ChauffeurDescription} />
          <meta itemProp="description" content={t.ChauffeurDescription} />
          <meta property="og:description" content={t.ChauffeurDescription} />
          <meta name="twitter:description" content={t.ChauffeurDescription} /> */}

          <link
            rel="apple-touch-icon"
            href={`https://www.chauffeur-hub.com/static/media/Group%2023582.a040e275ea7ecee63ae1311c02bfa8cc.svg`}
          />
          {/* <!-- Google / Search Engine Tags --> */}

          <meta
            itemProp="image"
            content={`https://www.chauffeur-hub.com/static/media/Group%2023582.a040e275ea7ecee63ae1311c02bfa8cc.svg`}
          />

          {/* <!-- Facebook Meta Tags --> */}

          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={`https://www.chauffeur-hub.com/static/media/Group%2023582.a040e275ea7ecee63ae1311c02bfa8cc.svg`}
          />
          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />

          <meta property="twitter:domain" content="user.chauffeur-hub.com" />

          <meta
            name="twitter:image"
            content={`https://www.chauffeur-hub.com/static/media/Group%2023582.a040e275ea7ecee63ae1311c02bfa8cc.svg`}
          />
        </Helmet>
        <Routes t={t} />
      </main>
      <Footer t={t} />
      <NavBottom />
      <a
        href="https://wa.me/+971544462611?text=I'm%20interested%20"
        target="_blank"
        rel="noreferrer"
        className="wats-btn"
      >
        <div class="message-container">
          <span class="online"></span>
          {t.wtsText}
        </div>
        <img src={wtsIcon} alt="wa" />
      </a>
    </ConfigProvider>
  );
}

export default App;
