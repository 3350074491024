import styles from "./Footer.module.scss";
import { Image } from "antd";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import img_3 from "../../public/images/Mask Group 77.png";
import img_4 from "../../public/images/Group 25854.svg";
import img_6 from "../../public/images/FACEBOOK.png";
import img_7 from "../../public/images/TWITTER.png";
import img_8 from "../../public/images/INSTAGRAM.png";
import img_9 from "../../public/images/YOUTUBE.png";
import linkedin from "../../public/images/linkedin.png";
import snapchat from "../../public/images/snapchat.png";
import tiktok from "../../public/images/tiktok.png";
import { useSelector } from "react-redux";
import { FaLocationArrow } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import FooterHero from "../FooterHero/FooterHero";

const Footer = ({ t }) => {
  const { currentUser, currentLang } = useSelector(
    (state) => state?.entities?.user
  );
  return (
    <footer className={styles.footer_wrapper}>
      <div className={styles.footer_cover}>
        <FooterHero t={t} />
        <div className="container">
          <div className={styles.cover_holder}>
            <div className={styles.cover_text}>
              <h2>{t.downloadApp}</h2>
              <p>{t.footerAboutText}</p>
              {/* <div className={styles.apps_links}>
                <Link to="/">
                  <Image src={img_1} alt="img" preview={false} />
                </Link>
                <Link to="/">
                  <Image src={img_2} alt="img" preview={false} />
                </Link>
                <Link to="/">
                  <Image src={appgallary} alt="img" preview={false} />
                </Link>
              </div> */}
            </div>
            <div className={styles.cover_image}>
              <Image src={img_3} alt="img" preview={false} />
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className={styles.footer_content}>
        <div className="container">
          <div className={styles.footer_content_holder}>
            <Row gutter={[30, 60]} align="top" justify="start">
              <Col xs={20} md={12} xl={9}>
                <div className={styles.brand}>
                  <div className={styles.brand_logo}>
                    <Link to="/">
                      <Image src={img_4} alt="img" preview={false} />
                    </Link>
                  </div>
                  <div className={styles.brand_text}>
                    <p>{t.Asanindividualorcompany}</p>
                    {/* <div className={styles.address}>
                      <Image src={img_5} alt="img" preview={false} />
                      <p>{t.footerAddress}</p>
                    </div> */}
                    <ul className="contact-info-list">
                      <li className="d-flex pb-3">
                        <div className="contact-icon mr-3">
                          <FaLocationArrow />
                        </div>
                        <div className="contact-text">
                          <h5 className="mb-1">{t.ourLocation}</h5>
                          <p>{t.footerAddress}</p>
                        </div>
                      </li>
                      <li className="d-flex pb-3">
                        <div className="contact-icon mr-3">
                          <AiOutlineMail />
                        </div>
                        <div className="contact-text">
                          <h5 className="mb-1">{t.EmailAddress}</h5>
                          <p>
                            <a href="mailto:Info@chauffeur-hub.com">
                              Info@chauffeur-hub.com
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.social}>
                    <h5>{t.WeAreOnSocialMedia}</h5>
                    <ul>
                      <li>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://www.facebook.com/profile.php?id=100080104555253&mibextid=LQQJ4d"
                        >
                          <Image src={img_6} alt="img" preview={false} />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://twitter.com/chauffeur_hub?s=11&t=3enLfUmEbHtGXzdr83RxEQ"
                        >
                          <Image src={img_7} alt="img" preview={false} />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://instagram.com/chauffeurhub?igshid=MzRlODBiNWFlZA=="
                        >
                          <Image src={img_8} alt="img" preview={false} />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://www.youtube.com/@chauffeurhub1156"
                        >
                          <Image src={img_9} alt="img" preview={false} />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://www.linkedin.com/company/chauffeur-hub/"
                        >
                          <Image src={linkedin} alt="img" preview={false} />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://t.snapchat.com/dur9gS4D"
                        >
                          <Image src={snapchat} alt="img" preview={false} />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://www.tiktok.com/@chauffeurhub"
                        >
                          <Image src={tiktok} alt="img" preview={false} />
                        </a>
                      </li>
                      {/* <li>
                        <a rel="noreferrer" target="_blank" href="/">
                          <Image src={img_11} alt="img" preview={false} />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xs={20} md={{ span: 12, push: 3 }} xl={{ span: 5, push: 0 }}>
                <div className={styles.footer_list_holder}>
                  <h4 className={styles.footer_list_title}>{t.services}</h4>
                  <ul>
                    <li>
                      <Link
                        to={{
                          pathname: "/" + currentLang + "/order-delivery",
                          state: { serviceId: 1 },
                        }}
                      >
                        {t.Delivery}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: "/" + currentLang + "/order-delivery",
                          state: { serviceId: 2 },
                        }}
                      >
                        {t.PersonalShopper}
                      </Link>
                    </li>
                    <li>
                      {/* <Link to={"/rent-car"}> */}
                      <a href="#;">
                        {t.Renting}{" "}
                        <span className="comming-soon">COMING SOON</span>{" "}
                      </a>
                      {/* </Link> */}
                    </li>
                    <li>
                      <Link to={"/" + currentLang + "/contact-us"}>
                        {t.ShareRequirments}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={20} md={12} xl={5}>
                <div className={styles.footer_list_holder}>
                  <h4 className={styles.footer_list_title}>{t.Company}</h4>
                  <ul>
                    <li>
                      <Link to={"/" + currentLang + "/blogs"}>{t.Blogs}</Link>
                    </li>
                    <li>
                      <Link to={"/" + currentLang + "/terms-conditions"}>
                        {t.TermsAndCondition}
                      </Link>
                    </li>
                    <li>
                      <Link to={"/" + currentLang + "/faq"}>{t.FAQ}</Link>
                    </li>
                    <li>
                      <Link to={"/" + currentLang + "/complaint-suggestions"}>
                        {t.ComplaintAndSuggestions}
                      </Link>
                    </li>
                    <li>
                      <Link to={"/" + currentLang + "/contact-us"}>
                        {t.Help}
                      </Link>
                    </li>
                    <li>
                      <Link to={"/" + currentLang + "/privacy-policy"}>
                        {t.PrivacyPolicy}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={20} md={{ span: 12, push: 3 }} xl={{ span: 5, push: 1 }}>
                <div className={styles.footer_list_holder}>
                  <h4 className={styles.footer_list_title}>{t.Profile}</h4>
                  <ul>
                    <li>
                      <Link
                        to={
                          currentUser
                            ? "/" + currentLang + "/profile"
                            : "/" + currentLang + "/login"
                        }
                      >
                        {t.MyProfile}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          currentUser
                            ? "/" + currentLang + "/orders"
                            : "/" + currentLang + "/login"
                        }
                      >
                        {t.MyOrders}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          currentUser
                            ? "/" + currentLang + "/address"
                            : "/" + currentLang + "/login"
                        }
                      >
                        {t.MyAdresses}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          currentUser
                            ? "/" + currentLang + "/my-wallet"
                            : "/" + currentLang + "/login"
                        }
                      >
                        {t.MyWallet}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <div className={styles.footer_copyright}>
                  <p>
                    &copy;{t.CopyrightsReservedFor}&nbsp;
                    <Link to={"/"}>{t.chauffeurHub}</Link>&nbsp;
                    {t.curentYear}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className={styles.side_image}></div>
      </div>
    </footer>
  );
};

export default Footer;
