import { useState } from "react";
import "./VideoPlay.scss";
import { Col, Modal, Row } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// import {PlayCircleOutlined} from "@ant-design/icons"
import { BsFillPlayCircleFill } from "react-icons/bs";
// import {VscPlay} from "react-icons/vsc"
// import VideoPlayerPage from '../../pages/VideoPlayerPage/VideoPlayerPage'
const VideoPlay = ({ t }) => {
  const [show, setShow] = useState(false);
  const handelClose = () => setShow(false);
  const handelShow = () => setShow(true);

  const [isPlaying, setIsPlaying] = useState(false);

  const { currentLang } = useSelector((state) => state?.entities?.user);
  const handleClick = () => {
    setIsPlaying(true);
  };
  return (
    <div className="home_video">
      {/* <div className={show ? "show" : "unshow"}>
        <VideoPlayerPage handelClose={handelClose} />
      </div> */}
      <Modal
        visible={show}
        onCancel={handelClose}
        footer={null}
        className="vedio-modal-wrapper"
        destroyOnClose
      >
        <iframe
          src="//www.youtube.com/embed/AU2Ivgg_28U"
          frameborder="0"
          allowFullScreen
        ></iframe>
      </Modal>
      <div className="video-player-frame">
        {/* {isPlaying ?  */}
        <iframe
          src={
            currentLang == "en"
              ? "//www.youtube.com/embed/fraxNv7Kr8M"
              : "//www.youtube.com/embed/AU2Ivgg_28U"
          }
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        ></iframe>
        {/* : <div className="video_image" onClick={handleClick}></div> } */}
      </div>
      {/* <div className="container">
        <Row className="video_content">
          <Col>
            <div className="video_inf">
              <h2 className="video_title">{t.videotitle}</h2> 
              <p>{t.videotitle2}</p>
            </div>
            <div className="video_promo_content">
              <Link
                onClick={handelShow}
                hrefLang="https://www.youtube.com/watch?v=AU2Ivgg_28U"
              >
                <span>
                  <BsFillPlayCircleFill />
                </span>
              </Link>
            </div>
          </Col>
        </Row>
      </div> */}
    </div>
  );
};

export default VideoPlay;
